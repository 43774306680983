var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"color":hover ? 'grey lighten-3' : 'white',"outlined":""},on:{"dblclick":function($event){return _vm.$emit('click-thumb', _vm.data)}}},[(_vm.thumbUrl)?_c('v-img',{staticClass:"white--text align-center custom-thumb",attrs:{"src":_vm.thumbUrl,"height":"200px","align":"center","justify":"center","contain":""}}):_c('v-img',{staticClass:"white--text align-center custom-thumb",attrs:{"height":"200px","align":"center","justify":"center"}},[_c('v-icon',{staticClass:"thumb-icon",attrs:{"color":_vm.iconColor}},[_vm._v("mdi-database")])],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-icon',{attrs:{"color":_vm.iconColor}},[_vm._v("mdi-database-outline")]),_c('v-tooltip',{attrs:{"open-delay":"1000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-subtitle',_vm._g(_vm._b({staticClass:"custom-subtitle prevent-select"},'v-card-subtitle',attrs,false),on),[_vm._v(_vm._s(_vm.data.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.data.name))])]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('ContentActionsPopup',{attrs:{"data":_vm.data,"filter-type":_vm.repositoryNodeType()}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }