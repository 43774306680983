var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ActionViewContainer',{attrs:{"title-icon":_vm.icon,"breadcrumbsItems":_vm.formattedBreadcrumbs,"actions":_vm.actions,"entryData":_vm.entryData,"title":"attach_security_groups"},on:{"add-security-group":_vm.openAddModal}},[_c('template',{slot:"table"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.nodeSecurityGroups,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.security_group_key",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.security_group_key))]),_c('br'),(item.inherited)?_c('span',[_vm._v("("+_vm._s(_vm.$t('inherited'))+")")]):(item.recursive)?_c('span',[_vm._v("("+_vm._s(_vm.$t('recursive'))+")")]):_vm._e()]}},{key:"item.mark_collection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mark_collection ? item.mark_collection.map(function (mark) { return mark.security_mark_key; }).join(', ') : null)+" ")]}},{key:"item.filters",fn:function(ref){
var item = ref.item;
return [(item.filter_key || item.filter_node_key)?[(item.filter_key)?_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('global'))+":")]),_vm._v(" "+_vm._s(item.filter_key)+" ")]):_vm._e(),_c('br'),(item.filter_node_key)?_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('local'))+":")]),_vm._v(" "+_vm._s(item.filter_node_key)+" ")]):_vm._e()]:[_vm._v(" - ")]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.inherited)?[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.editSecurityGroup(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.editSecurityGroupFilter(item)}}},[_vm._v(" mdi-filter-plus ")]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.removeSecurityGroup(item)}}},[_vm._v(" mdi-minus-circle ")])]:[_vm._v(" - ")]]}}])}),_c('DefaultModal',{ref:"modal",attrs:{"title":_vm.$t('add_security_group'),"loading":_vm.loading},on:{"submit":_vm.saveSecurityGroup},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-select',{attrs:{"items":_vm.filteredSecurityGroups,"item-text":"security_group_key","item-value":"security_group_id","label":_vm.$t('security_groups'),"readonly":_vm.editing,"outlined":""},model:{value:(_vm.selectedSecurityGroupId),callback:function ($$v) {_vm.selectedSecurityGroupId=$$v},expression:"selectedSecurityGroupId"}}),_c('v-switch',{attrs:{"label":_vm.$t('recursive')},model:{value:(_vm.recursive),callback:function ($$v) {_vm.recursive=$$v},expression:"recursive"}})]},proxy:true}])}),_c('FilterAssocModal',{ref:"filter_assoc_modal",on:{"submit-filter":_vm.saveSecurityGroupFilter}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }