<template>
  <ActionViewContainer
    :title-icon="icon"
    :breadcrumbsItems="formattedBreadcrumbs"
    :actions="actions"
    :entryData="entryData"
    @add-security-group="openAddModal"
    title="attach_security_groups"
  >
    <template slot="table">
      <v-data-table
        :headers="headers"
        :items="nodeSecurityGroups"
        :loading="loading"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:item.security_group_key="{ item }">
          <span>{{ item.security_group_key }}</span><br>
          <span v-if="item.inherited">({{ $t('inherited') }})</span>
          <span v-else-if="item.recursive">({{ $t('recursive') }})</span>
        </template>

        <template v-slot:item.mark_collection="{ item }">
          {{ item.mark_collection ? item.mark_collection.map(mark => mark.security_mark_key).join(', ') : null }}
        </template>

        <template v-slot:item.filters="{ item }">
          <template v-if="item.filter_key || item.filter_node_key">
            <span v-if="item.filter_key">
              <span class="font-weight-bold">{{ $t('global') }}:</span> {{item.filter_key}}
            </span>
            <br>
            <span v-if="item.filter_node_key">
              <span class="font-weight-bold">{{ $t('local') }}:</span> {{item.filter_node_key}}
            </span>
          </template>

          <template v-else> - </template>
        </template>

        <template v-slot:item.actions="{ item }">

          <template v-if="!item.inherited">
            <v-icon
              class="mr-2"
              @click.stop="editSecurityGroup(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              class="mr-2"
              @click.stop="editSecurityGroupFilter(item)"
            >
              mdi-filter-plus
            </v-icon>

            <v-icon
              class="mr-2"
              @click.stop="removeSecurityGroup(item)"
            >
              mdi-minus-circle
            </v-icon>
          </template>

          <template v-else> - </template>

        </template>
        
      </v-data-table>

      <DefaultModal
        :title="$t('add_security_group')"
        ref="modal"
        @submit="saveSecurityGroup"
        :loading="loading"
      >
        <template v-slot:content>
          <v-select
            v-model="selectedSecurityGroupId"
            :items="filteredSecurityGroups"
            item-text="security_group_key"
            item-value="security_group_id"
            :label="$t('security_groups')"
            :readonly="editing"
            outlined
          ></v-select>

          <v-switch
            :label="$t('recursive')"
            v-model="recursive"
          >
          </v-switch>
        </template>
      </DefaultModal>

      <FilterAssocModal 
        @submit-filter="saveSecurityGroupFilter"
        ref="filter_assoc_modal"
      />

    </template>

  </ActionViewContainer>
</template>

<style scoped>
  >>>.word-break {
    word-break: break-word;
  }
</style>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

import ActionViewContainer from '@/components/content/ActionViewContainer'

import DefaultModal from '@/components/DefaultModal'
import FilterAssocModal from '@/components/node/FilterAssocModal'

import AppActions from '@/store/app/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import SecurityGroupActions from '@/store/operation/security_group/actions-types'

export default {
  name: "AttachSecurityGroup",

  components: {
    ActionViewContainer,
    DefaultModal,
    FilterAssocModal,
  },

  data() {
    return {
      loading: false,
      actions: [
        {
          text: "add_security_group",
          eventToEmit: "add-security-group"
        }
      ],
      entryData: {},
      headers: [
        {text: 'Key', value: 'security_group_key'},
        {text: 'Marks', value: 'mark_collection', cellClass: 'word-break'},
        {text: 'Filters', value: 'filters'},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false}
      ],
      nodeSecurityGroups: [],
      allSecurityGroups: [],
      selectedSecurityGroupId: null,
      recursive: null,
      editing: false
    }
  },

  computed: {
    ...mapGetters('app', [
        'getApplicationInstanceById'
    ]),

    ...mapState({
      userRoles: state => state.core.auth.userRoles,
      breadcrumb: state => state.content.entry.breadcrumb,
      entries: state => state.content.entry.entries,
      nodeCardActions: state => state.content.actions.nodeCardActions
    }),

    icon() {
      return this.nodeCardActions.find(action => action.key == 'attach_security_groups').icon
    },

    filteredSecurityGroups() {
      return this.allSecurityGroups.filter(secGroup => {
        let has = false

        this.nodeSecurityGroups.forEach(nodeSecGroup => {
          if (nodeSecGroup.security_group_id == secGroup.security_group_id) {
            has = true
            return
          }
        })

        return !has
      })
    },

    formattedBreadcrumbs() {
      let formatted = [
        {
          prependIcon: 'mdi-home-outline',
          iconColor: 'primary',
          to: {
            name: 'ContentHome',
            params: { appId: this.$route.params.appId }
          },
          link: true,
          disabled: false,
          ripple: true
        },
      ]

      const reversed = [].concat(this.breadcrumb).reverse()

      reversed.forEach((element, index, array) => {
        formatted.push({
          to: {
            name: 'ContentNavigation',
            params: { parentId: element.node_id }
          },
          text: element.name,
          ripple: true,
          disabled: Object.is(array.length - 1, index),
        })
      })

      return formatted
    },
  },

  mounted() {
    this.loadData()
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('content/entry', [
      EntryActions.LIST_SUBFOLDER,
      EntryActions.GET_ENTRY_DATA,
    ]),

    ...mapActions('operation/security_group', [
      SecurityGroupActions.GET_SECURITY_GROUPS_BY_NODE,
      SecurityGroupActions.GET_SECURITY_GROUPS_BY_TENANT,
      SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP,
      SecurityGroupActions.ADD_SECURITY_GROUP_NODE_RELATIONSHIP,
      SecurityGroupActions.REMOVE_SECURITY_GROUP_NODE_RELATIONSHIP
    ]),

    loadData() {
      this.loading = true

      let nodeId = this.$route.params.nodeId

      this[EntryActions.LIST_SUBFOLDER]({parentId: nodeId})
        .then(() => {
          this.loading = false
        })
        .catch(() => this.loading = false)

      this[EntryActions.GET_ENTRY_DATA](nodeId)
        .then((data) => {
          this.entryData = data
          this.loading = false
        })
        .catch(() => this.loading = false)

      this[SecurityGroupActions.GET_SECURITY_GROUPS_BY_NODE](nodeId)
        .then((data) => {
          this.nodeSecurityGroups = data.items
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    openAddModal() {
      this.editing = false
      this.recursive = true
      this.openModal()
    },

    openModal() {
      this.loading = true
      this[SecurityGroupActions.GET_SECURITY_GROUPS_BY_TENANT](this.entryData.tenant_id)
        .then((data) => {
          this.allSecurityGroups = data.items
          this.loading = false
        })
        .catch(() => this.loading = false)
      this.$refs.modal.openModal()
    },

    saveSecurityGroup() {
      let params = {
        nodeId: this.$route.params.nodeId,
        securityGroupId: this.selectedSecurityGroupId,
        data: {
          recursive: this.recursive == true,
          item_order: 1,
          filter_id: null
        }
      }

      this[SecurityGroupActions.ADD_SECURITY_GROUP_NODE_RELATIONSHIP](params)
        .then(() => {
          this.$refs.modal.submitting = false
          this.$refs.modal.closeModal()
          this.editing = false
          this.loadData()
        })
        .catch(() => {
          this.$refs.modal.submitting = false
          this.editing = false
        })
    },

    editSecurityGroup(item) {
      this.editing = true
      this.selectedSecurityGroupId = item.security_group_id
      this.recursive = item.recursive

      this.openModal()
    },

    editSecurityGroupFilter(item) {
      this.selectedSecurityGroupId = item.security_group_id
      this.recursive = item.recursive

      this.$refs.filter_assoc_modal.tenantIdFilter = item.tenant_id

      this.$refs.filter_assoc_modal.open(item.relation_filter_id)
    },

    saveSecurityGroupFilter(form) {
      const filterId = parseInt(form.get('filter')) || null

      let params = {
        nodeId: this.$route.params.nodeId,
        securityGroupId: this.selectedSecurityGroupId,
        data: {
          recursive: this.recursive == true,
          item_order: 1,
          filter_id: filterId
        }
      }

      this[SecurityGroupActions.ADD_SECURITY_GROUP_NODE_RELATIONSHIP](params)
        .then(() => {
          this.$refs.filter_assoc_modal.loading = false

          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('filter_updated'))

          this.$refs.filter_assoc_modal.close()

          this.loadData()
        })
        .catch(() => {
          this.$refs.filter_assoc_modal.loading = false
        })
    },

    removeSecurityGroup(item) {
      this.loading = true

      let params = {
        nodeId: this.$route.params.nodeId,
        securityGroupId: item.security_group_id
      }

      this[SecurityGroupActions.REMOVE_SECURITY_GROUP_NODE_RELATIONSHIP](params)
        .then(() => {
          this.loadData()
        })
        .catch(() => {
          this.loading = false
        })
    },

    isInheritedSecurityGroup(security_group) {
      return security_group.relation_node_id != this.$route.params.nodeId && false
    },
  }
}

</script>
